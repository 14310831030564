@import 'theme/variables.scss';

.body {
  overflow-y: auto;
  background-color: $color-black-95;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: auto;

  .container {
    max-width: 492px;
    margin: 64px 32px 32px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 16px;
    overflow: hidden !important;
  }

  .logo {
    color: unset;
    display: flex;
    justify-content: center;
    margin-top: 64px;

    &:hover {
      color: unset;
    }
  }

  .language {
    bottom: 16px;

    @media (min-width: 1100px) {
      position: fixed;
      right: 32px;
      bottom: 32px;
    }
  }
}
